<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import apiTool from '@/command/apiTool'
import DrawerForm from '../../../components/DrawerForm'
import { postAction, getAction } from '@/command/netTool'
import moment from 'moment'
export default {
  name: 'problemManagement',
  data() {
    return {
      ...api.command.getState(),
      dataId: null,
    }
  },
  mounted() {
    this.handleGetInit()
  },
  methods: {
    handleGetInit() {
      const { id } = this.$route.query
      api.command.getList.call(this, {
        url: '/farmFaq/page',
        current: 1,
        paramsValue: {
          faqType: id,
        },
      })
    },
    getHeader() {
      return [
        {
          name: '问题名称',
          key: 'faqTitle',
          type: 'input',
        },
        {
          label: '',
          key: '',
          type: 'text',
        },
      ]
    },
    getColumns() {
      return [
        {
          title: 'ID',
          dataIndex: 'id',
          align: 'left',
          sorter: (a, b) => a.id - b.id,
        },
        {
          dataIndex: 'faqTitle',
          title: '问题名称',
          align: 'left',
        },
        {
          dataIndex: 'sort',
          title: '排序',
          align: 'left',
          sorter: (a, b) => a.sort - b.sort,
        },
        {
          dataIndex: 'upDown',
          title: '状态',
          type: 'badge',
          filters: [
            {
              text: '上架',
              value: '0',
            },
            {
              text: '下架',
              value: '1',
            },
          ],
          onExport: (records) => {
            return ['上架', '下架'][records]
          },
          filterMultiple: false,
          render(data) {
            return {
              showDot: true,
              name: data == 0 ? '上架' : '下架',
              color: data == 0 ? 'green' : 'red',
            }
          },
        },
        {
          dataIndex: 'a9',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          typeData: ({ records }) => {
            return [
              {
                display: true,
                name: records.upDown == 0 ? '下架' : '上架',
                onClick: () => {
                  api.command.editGET.call(this, {
                    url: `/farmFaq/${records.upDown == '0' ? 'down' : 'up'}?id=${records.id}`,
                  })
                },
              },
              {
                display: true,
                name: '编辑',
                onClick: () => {
                  this.onRowSelect(records)
                },
              },
              {
                display: records.upDown !== '0',
                name: '删除',
                type: 'pop',
                popTitle: '是否确认删除?',
                onClick: () => {
                  api.command.delDelArr.call(this, {
                    url: `/farmFaq?id=${records.id}`,
                  })
                },
              },
            ].filter((e) => e.display)
          },
        },
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => {
            this.onRowSelect()
          },
        },
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量删除',
              onClick: (data) => {
                let idList = data.map((e) => e.id)
                return api.command.delPut.call(this, {
                  url: '/farmFaq/deleteBatch',
                  params: { idList },
                })
              },
            },
          ],
        },
      ]
    },
    getDrawerForm(source, formData) {
      const form = [
        {
          form: [
            {
              name: '问题名称',
              type: 'input',
              key: 'faqTitle',
              maxLength: 30,
              showCount: true,
              rules: [
                {
                  required: true,
                  type: 'string',
                },
              ],
            },
            {
              name: '问题答案',
              type: 'textArea',
              key: 'faqContent',
              maxLength: 300,
              showCount: true,
              rules: [
                {
                  required: true,
                  type: 'string',
                },
              ],
            },
            {
              name: '排序',
              type: 'inputNumber',
              key: 'sort',
              props: {
                placeholder: '请输入0-100的数字',
                min: 0,
                max: 100,
              },
            },
          ],
        },
      ]
      return form
    },
    onRowSelect(records = {}) {
      if (records.id) {
        this.dataId = records.id
        getAction('/farmFaq/detail?id=' + records.id).then((result) => {
          if (result.code == 200) {
            this.handleStatePopup(result.data)
          } else {
            this.$message.error(result.msg)
          }
        })
      } else {
        this.dataId = null
        this.handleStatePopup()
      }
    },
    handleStatePopup(
      source = {
        sort: '',
        faqContent: '',
        faqTitle: '',
      }
    ) {
      let that = this
      const { id } = this.$route.query
      apiTool.showDrawer({
        title: '新增问题',
        width: '720px',
        view: DrawerForm,
        viewProps: {
          data: (formData) => that.getDrawerForm(source, formData),
          form: source,
          formProps: {
            layout: 'horizontal',
            cols: 24,
            labelCol: { span: 3 },
            wrapperCol: { span: 21 },
          },
        },
        success: ({ data, setHidden }) => {
          if (JSON.stringify(data) == '{}') return
          // return
          api.command[!that.dataId ? 'create' : 'edit']
            .call(that, {
              url: `/farmFaq`,
              params: {
                ...data,
                id: that.dataId,
                faqType: id,
              },
            })
            .then(setHidden)
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '取消',
              onClick: close,
            },
            {
              name: '确认',
              type: 'primary',
              onClick: submit,
            },
          ]
        },
      })
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  },
}
</script>
